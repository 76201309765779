//
// Wells
// --------------------------------------------------


// Base class
.well {
  min-height: 20pt;
  padding: 19pt;
  margin-bottom: 20pt;
  background-color: @well-bg;
  border: 1pt solid @well-border;
  border-radius: @border-radius-base;
  .box-shadow(inset 0 1pt 1pt rgba(0,0,0,.05));
  blockquote {
    border-color: #ddd;
    border-color: rgba(0,0,0,.15);
  }
}

// Sizes
.well-lg {
  padding: 24pt;
  border-radius: @border-radius-large;
}
.well-sm {
  padding: 9pt;
  border-radius: @border-radius-small;
}
